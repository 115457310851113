@use '@suits/ss-design-system/dist/ss-components/styles/sass/mixins/_breakpoints.scss' as bp;

$side-slider-z-index: 30;

.side-slider {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: $side-slider-z-index;
  background-color: var(--color-surface);
  overflow: hidden auto;
  transition:
    background-color 0.4s,
    transform 0.2s cubic-bezier(0.6, 0, 0.2, 1);

  @include bp.respond-from('md') {
    width: 454px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $side-slider-z-index - 5;
    background-color: rgba(0 0 0 / 30%);
    transition: 0.4s ease-in-out;
  }

  &__overlay-closed {
    visibility: hidden;
    opacity: 0;
  }

  &__overlay-open {
    visibility: visible;
    opacity: 1;
  }

  &--direction-left {
    left: 0;
    transform: translateX(-100%);
  }

  &--direction-right {
    right: 0;
    transform: translateX(100%);
  }

  &__close-button {
    color: var(--color-primary);
    z-index: 30;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    > div {
      font-size: 22px;
    }

    @include bp.respond-from('md') {
      display: none;
    }
  }

  &--fullscreen {
    &-always {
      width: 100%;

      .side-slider__close-button {
        display: initial;
      }
    }

    &-mobile {
      @include bp.respond-until('md') {
        width: 100%;
      }
    }

    &-never {
      @include bp.respond-from('md') {
        width: 454px;
      }

      @include bp.respond-until('md') {
        max-width: 330px;
        width: calc(100% - 45px);
      }
    }
  }
}

.side-slider--open {
  transform: translateX(0);
  transition:
    background-color 0.4s,
    transform 0.4s cubic-bezier(0.2, 0.4, 0.2, 1);

  &.side-slider--direction-left {
    transform: translateX(0);
  }

  &.side-slider--direction-right {
    transform: translateX(0);
  }
}
